<template>
  <div class="flex items-start">
    <div class="menus" v-if="$store.state.isRealName">
      <div class="menu-title">个人中心</div>
      <div class="menu" :class="{'active': $route.path == item.url}" v-for="(item, index) in menuList" :key="index" 
      @click="$router.push(item.url)">{{item.title}}</div>
    </div>
    <div class="menus" v-else>
       <div class="menu-title">账号认证</div>
       <div class="menu" :class="{'active': $route.path == '/user/userAuth'}">会员中心</div>
    </div>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'User',
  data() {
    return {
      menuList: [
        {
          url: '/user/userAccount',
          title: '账户信息'
        },
        {
          url: '/user/userAsset',
          title: '账户资产'
        },
        {
          url: '/user/userMember',
          title: '会员中心'
        },
        {
          url: '/user/userAccountSafty',
          title: '账户与安全'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.menus {
  width: 180px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  margin-right: 24px;
  .menu-title {
    padding: 24px 0;
    font-size: 16px;
    color: #65666F;
  }
  .menu {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    cursor: pointer;
  }
  .active {
    background-color: #FFF2ED;
    color: var(--theme-color);
    border-right: 3px solid var(--theme-color);
  }
}
.content {
  flex: 1;
}
</style>